import {
  SET_SCROLL_DIRECTION_FROM_POSITION,
  SET_USER_SCROLLING,
  SET_SCROLL_POSITION_BEFORE_OPENING_GALLERY
 } from '../constants';

export const setGalleryScrollPreviousPos = () => {
  return (dispatch, getState) => {
    const { scroll } = getState();
    const scrollPositionBeforeOpenGallery = scroll.position;

    return dispatch({
      type: SET_SCROLL_POSITION_BEFORE_OPENING_GALLERY,
      scrollPositionBeforeOpenGallery
    })
  }
}

const setPositionAndDirection = (position, direction) => ({
  type: SET_SCROLL_DIRECTION_FROM_POSITION,
  position,
  direction
});

const setIsScrolling = isScrolling => ({
  type: SET_USER_SCROLLING,
  isScrolling
})

export const setScrollDirectionFromPosition = newPosition => {

  return (dispatch, getState) => {
    const state = getState();
    
    
    const statePosition = state.scroll.position;
    const prevDirection = state.scroll.direction;

    
    if (!state.scroll.isScrolling) {
      let nextDirection;
  
      if (statePosition === newPosition) {
        nextDirection = prevDirection;
      }
  
      if (statePosition < newPosition) {
        nextDirection = 'DOWN'
      }
  
      if (statePosition > newPosition) {
        nextDirection = 'UP'
      }

      dispatch(setPositionAndDirection(newPosition, nextDirection));
      setTimeout(() => {
        dispatch(setIsScrolling(false))
      }, 1)
    }
    
  }
}