import get from 'lodash/get';
import { SET_CATEGORY, SET_CATEGORIES_ORDER, SET_GALLERY_BY_ID } from '../constants';

export const setCategory = category => (dispatch, getState) => {
  const state = getState(); 
  const selectedCategory = get(state, 'category.selected')
  const isSliderOpen = get(state, 'slider.isOpen')

  if (isSliderOpen && selectedCategory !== category ) {
    dispatch({
      type: SET_GALLERY_BY_ID,
      id: false
    })
  }

  return dispatch({
    type: SET_CATEGORY,
    category
  });
};

export const setCategoryOrder = category => ({
  type: SET_CATEGORIES_ORDER,
  category
});