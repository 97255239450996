import Slider from './Slider';

import { connect } from 'react-redux';
import { getIsSliderOpen } from '../../store/selectors/slider';
import { closeSlider } from '../../store/actions/slider';
import { isEstudioOpen, getCategoryListOpen, isCategoryListOpen } from '../../store/selectors/slider';
import { getScrollData } from '../../store/selectors/scroll';
import { nextGallery } from '../../store/actions/gallery'

const mapStateToProps = state => ({
  isOpen: getIsSliderOpen(state),
  scrollDirection: getScrollData(state, 'direction'),
  isUserScrolling: getScrollData(state, 'isScrolling'),
  isEstudioOpen: isEstudioOpen(state),
  openCategoryList: getCategoryListOpen(state),
  isCategoryListOpen: isCategoryListOpen(state)
});

const mapDispatchToProps = {
  closeSlider,
  nextGallery
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);