export const CATEGORY_CONCESIONARIAS = 'CATEGORY_CONCESIONARIAS';
export const CATEGORY_VIVIENDAS = 'CATEGORY_VIVIENDAS';
export const CATEGORY_BARES = 'CATEGORY_BARES';
export const CATEGORY_ESTUDIO = 'CATEGORY_ESTUDIO';
export const SHOW_CATEGORY_LIST = 'SHOW_CATEGORY_LIST';

export const SET_GALLERY_BY_ID = 'SET_GALLERY_BY_ID';

export const SET_CATEGORIES_ORDER = 'SET_CATEGORIES_ORDER';

export const SET_CATEGORY = 'SET_CATEGORY';

export const SET_SCROLL_DIRECTION_FROM_POSITION = 'SET_SCROLL_DIRECTION_FROM_POSITION';

export const SET_USER_SCROLLING = 'SET_USER_SCROLLING';
export const SET_SCROLL_POSITION_BEFORE_OPENING_GALLERY = 'SET_SCROLL_POSITION_BEFORE_OPENING_GALLERY';

export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU';
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';

