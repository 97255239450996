import { OPEN_MOBILE_MENU, CLOSE_MOBILE_MENU } from '../constants'
import get from 'lodash/fp/get'

const scrollToTopEvent = new Event('scroll_to_top')
export const scrollToTop = () => {
    window.dispatchEvent(scrollToTopEvent);
    return {
        type: 'SCROLL_TO_TOP'
    }
}

export const setDescriptionItemsByID = itemId => (dispatch, getState)  => {
    if(!itemId) {
        return dispatch({
            type: 'SET_DESCRIPTION_ITEMS',
            descriptionItems: null
        })
    }
    const state = getState();
    
    const itemYear = get(`galleryItems.${itemId}.year`)(state);
    const descriptionWithoutYear = get(`galleryItems.${itemId}.description`)(state) || []
    const [title, ...restOfDescription] = descriptionWithoutYear

    const descriptionItems = [ title, itemYear, ...(restOfDescription ? restOfDescription : []) ]
    
    return dispatch({
        type: 'SET_DESCRIPTION_ITEMS',
        descriptionItems
    })
}

export const openMobileMenu = () => {
    return {
        type: OPEN_MOBILE_MENU
    }
}

export const closeMobileMenu = () => {
    return {
        type: CLOSE_MOBILE_MENU
    }
}
