import Navigation from './Navigation';
import { connect } from 'react-redux';
import { setCategory } from '../../store/actions/category';
import { getSelectedCategory, getNavitemActive } from '../../store/selectors/category';
import { setGalleryById, setGalleryList } from '../../store/actions/gallery'
import { getIsSliderOpen } from '../../store/selectors/slider';
import { closeSlider } from '../../store/actions/slider';
import { openMobileMenu, closeMobileMenu, scrollToTop } from '../../store/actions/ui'
import { isMobileMenuOpen } from '../../store/selectors/ui'

const mapStateToProps = state => ({
  selectedCategory: getSelectedCategory(state),
  navitemActive: getNavitemActive(state),
  isSilderOpen: getIsSliderOpen(state),
  isMobileMenuOpen: isMobileMenuOpen(state,)
});

const mapDispatchToProps = {
  setCategory,
  setGalleryById,
  setGalleryList,
  closeSlider,
  openMobileMenu,
  closeMobileMenu,
  scrollToTop
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
