import { CATEGORY_CONCESIONARIAS } from '../constants';
import { CATEGORY_VIVIENDAS } from '../constants';
import { CATEGORY_BARES } from '../constants';

const nbsp = '\u00a0';

export const GALLERY_ITEMS_INITIAL_STATE = {
  1: {
    title: 'CASA PERNANCIC',
    category: CATEGORY_VIVIENDAS,
    introImage: '/images/home/01-casa-pernancic-viviendas.jpg',
    year: 2012,
    galleryImages: [
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-1.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-2.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-3.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-5.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-7.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-12.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-17.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-20.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-21.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-22.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-23.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-24.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-25.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-26.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-27.jpg',
      '/images/viviendas/casa-pernancic/casa-pernancic-viviendas-28.jpg'
    ],
    description: [
      'CASA PERNANCIC',
      nbsp,
      'Estancias del pilar',
      'Barrio Cerrado, Pilar, Bs As'
    ]
  },
  2: {
    title: 'BMW Mini',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/02-bmw-mini-comerciales.jpg',
    year: 2019,
    galleryImages: [
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-3.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-9.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-14.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-15.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-16.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-17.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-18.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-19.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-20.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-21.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-22.jpg',
      '/images/comerciales/bmw-mini/bmw-mini-comerciales-23.jpg',
    ],
    description: [
      'BMW Mini',
      nbsp,
      'Av. Pres. Figueroa Alcorta 7730, CABA',
      'PROY: Arq. José Ripani',
      'DO: Arq. Gonzalo Obes'
    ]
  },
  3: {
    title: 'DUCA Café',
    category: CATEGORY_BARES,
    introImage: '/images/home/03-duca-gastronomico.jpg',
    year: 2019,
    galleryImages: [
      '/images/gastronomico/duca/duca-gastronomico-1.jpg',
      '/images/gastronomico/duca/duca-gastronomico-2.jpg',
      '/images/gastronomico/duca/duca-gastronomico-3.jpg',
      '/images/gastronomico/duca/duca-gastronomico-4.jpg',
      '/images/gastronomico/duca/duca-gastronomico-5.jpg',
      '/images/gastronomico/duca/duca-gastronomico-6.jpg',
      '/images/gastronomico/duca/duca-gastronomico-7.jpg',
      '/images/gastronomico/duca/duca-gastronomico-8.jpg',
      '/images/gastronomico/duca/duca-gastronomico-9.jpg',
      '/images/gastronomico/duca/duca-gastronomico-10.jpg',
      '/images/gastronomico/duca/duca-gastronomico-11.jpg',
      '/images/gastronomico/duca/duca-gastronomico-12.jpg',
      '/images/gastronomico/duca/duca-gastronomico-13.jpg',
      '/images/gastronomico/duca/duca-gastronomico-14.jpg',
      '/images/gastronomico/duca/duca-gastronomico-15.jpg',
    ],
    description: [
      'DUCA Café',
      nbsp,
      'Thames 1759, CABA',
      nbsp,
      'PROY: Arq. Lucas Ripani',
      'Arq. Felicitas Navia',
      'DO: Arq. Martina Perez Campi',
      'Roxana Paik'
    ]
  },
  4: {
    title: 'Espasa Taller VW',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/04-espasa-taller-vw-comerciales.jpg',
    year: 2012,
    galleryImages: [
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-1.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-2.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-3.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-4.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-5.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-6.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-7.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-8.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-11.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-12.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-16.jpg',
      '/images/comerciales/espasa-taller-vw/espasa-taller-vw-comerciales-17.jpg',
    ],
    description: [
      'Espasa Taller VW',
      nbsp,
      'Av. Los Incas 4378, CABA',
      'PROY: Arq. José Ripani',
      'DO: Arq. Andrés Gervasio'
    ]
  },
  5: {
    title: 'Gintonería Rosario',
    category: CATEGORY_BARES,
    introImage: '/images/home/05-gintoneria-gastronomico.jpg',
    year: 2018,
    galleryImages: [
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-1.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-2.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-3.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-4.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-6.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-7.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-8.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-9.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-10.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-11.jpg',
      '/images/gastronomico/gintoneria/gintoneria-gastronomico-12.jpg',
    ],
    description: [
      'Gintonería Rosario',
      nbsp,
      'Balcarce 340, Rosario, Santa Fe',
      'PROY: Arq. Lucas Ripani',
      'Arq. Felicitas Navia, Emilia Carranza',
      'DO: Arq. Paulina Medina, Arq. Lucas Ripani'
    ]
  },
  6: {
    title: 'BMW',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/06-bmw-comerciales.jpg',
    year: 2010,
    galleryImages: [
      '/images/comerciales/bmw-6500/bmw-6500-comerciales-1.jpg',
      '/images/comerciales/bmw-6500/bmw-6500-comerciales-2.jpg',
      '/images/comerciales/bmw-6500/bmw-6500-comerciales-3.jpg',
      '/images/comerciales/bmw-6500/bmw-6500-comerciales-4.jpg',
      '/images/comerciales/bmw-6500/bmw-6500-comerciales-5.jpg',
      '/images/comerciales/bmw-6500/bmw-6500-comerciales-7.jpg',
      '/images/comerciales/bmw-6500/bmw-6500-comerciales-15.jpg',
    ],
    description: [
      'BMW',
      nbsp,
      'San Martin 6500'
    ]
  },
  7: {
    title: 'Taller Audi',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/07-taller-audi-comerciales.jpg',
    year: 2015,
    galleryImages: [
      '/images/comerciales/taller-audi/taller-audi-comerciales-1.jpg',
      '/images/comerciales/taller-audi/taller-audi-comerciales-2.jpg',
      '/images/comerciales/taller-audi/taller-audi-comerciales-3.jpg',
      '/images/comerciales/taller-audi/taller-audi-comerciales-4.jpg',
      '/images/comerciales/taller-audi/taller-audi-comerciales-5.jpg',
      '/images/comerciales/taller-audi/taller-audi-comerciales-6.jpg',
      '/images/comerciales/taller-audi/taller-audi-comerciales-7.jpg',
      '/images/comerciales/taller-audi/taller-audi-comerciales-8.jpg',
    ],
    description: [
      'Taller Audi',
      nbsp,
      'Av. San Juan 1740, CABA',
    ]
  },
  8: {
    title: 'Parque Bar',
    category: CATEGORY_BARES,
    introImage: '/images/home/08-parque-gastronomico.jpg',
    year: 2017,
    galleryImages: [
      '/images/gastronomico/parque/parque-gastronomico-1.jpg',
      '/images/gastronomico/parque/parque-gastronomico-2.jpg',
      '/images/gastronomico/parque/parque-gastronomico-3.jpg',
      '/images/gastronomico/parque/parque-gastronomico-4.jpg',
      '/images/gastronomico/parque/parque-gastronomico-10.jpg',
      '/images/gastronomico/parque/parque-gastronomico-11.jpg',
      '/images/gastronomico/parque/parque-gastronomico-12.jpg',
      '/images/gastronomico/parque/parque-gastronomico-13.jpg',
      '/images/gastronomico/parque/parque-gastronomico-14.jpg',
      '/images/gastronomico/parque/parque-gastronomico-15.jpg',
      '/images/gastronomico/parque/parque-gastronomico-16.jpg',
    ],
    description: [
      'Parque Bar',
      nbsp,
      'Thames 1472, CABA',
      nbsp,
      'PROY: Arq. Lucas Ripani',
      'Arq. Felicitas Navia, Arq. Laura Zuloaga'
    ]
  },
  9: {
    title: 'Estancias del Pilar',
    category: CATEGORY_VIVIENDAS,
    introImage: '/images/home/09-estancias-viviendas.jpg',
    year: 2008,
    galleryImages: [
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-1.jpg',
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-2.jpg',
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-3.jpg',
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-4.jpg',
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-5.jpg',
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-6.jpg',
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-7.jpg',
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-8.jpg',
      '/images/viviendas/estancias-del-pilar/estancias-viviendas-9.jpg',
    ],
    description: [
      'Estancias del Pilar',
      nbsp,
      'Barrio Cerrado, Pilar, Bs As',
    ]
  },
  10: {
    title: 'Boticario Bar',
    category: CATEGORY_BARES,
    introImage: '/images/home/10-boticario-gastronomico.jpg',
    year: 2017,
    galleryImages: [
      '/images/gastronomico/boticario/boticario-gastronomico-1.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-2.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-3.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-4.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-5.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-6.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-7.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-8.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-9.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-10.jpg',
      '/images/gastronomico/boticario/boticario-gastronomico-11.jpg',
    ],
    description: [
      'Boticario Bar',
      nbsp,
      'Honduras 5207, CABA',
      'PROY: Arq. Lucas Ripani',
      'COLAB. Arq. Ayelen Olivieri, Martín Faingold'
    ]
  },
  11: {
    title: 'Tours Citroën',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/11-tours-citroen-comerciales.jpg',
    year: 2007,
    galleryImages: [
      '/images/comerciales/tours-citroen/tours-citroen-comerciales-1.jpg',
      '/images/comerciales/tours-citroen/tours-citroen-comerciales-2.jpg',
      '/images/comerciales/tours-citroen/tours-citroen-comerciales-3.jpg',
      '/images/comerciales/tours-citroen/tours-citroen-comerciales-4.jpg',
    ],
    description: [
      'Tours Citroën',
      nbsp,
      'Panamericana Ramal Pilar KM 46800, Bs As',
    ]
  },
  12: {
    title: 'Tomate - Estación de sabores',
    category: CATEGORY_BARES,
    introImage: '/images/home/12-tomate-gastronomico.jpg',
    year: 2018,
    galleryImages: [
      '/images/gastronomico/tomate/tomate-gastronomico-1.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-2.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-3.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-4.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-5.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-6.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-7.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-8.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-9.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-10.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-11.jpg',
      '/images/gastronomico/tomate/tomate-gastronomico-12.jpg',
    ],
    description: [
      'Tomate - Estación de sabores',
      nbsp,
      'El Salvador 2646, CABA',
      nbsp,
      'PROY: Arq. Lucas Ripani',
      'Irina Teitelbaum'
    ]
  },
  13: {
    title: 'DS Store',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/13-ds-comerciales.jpg',
    year: 2018,
    galleryImages: [
      '/images/comerciales/ds/ds-comerciales-1.jpg',
      '/images/comerciales/ds/ds-comerciales-2.jpg',
      '/images/comerciales/ds/ds-comerciales-4.jpg',
      '/images/comerciales/ds/ds-comerciales-5.jpg',
      '/images/comerciales/ds/ds-comerciales-6.jpg',
      '/images/comerciales/ds/ds-comerciales-7.jpg',
      '/images/comerciales/ds/ds-comerciales-8.jpg',
    ],
    description: [
      'DS Store',
      nbsp,
      'Av. Libertador 14400, Martínez, Bs As',
      'PROY: Arq. José Ripani',
      'DO: Arq. Gonzalo Obes, Arq. Leonardo Baron'
    ]
  },
  14: {
    title: 'Wellbar',
    category: CATEGORY_BARES,
    introImage: '/images/home/14-wellbar-gastronomico.jpg',
    year: 2018,
    galleryImages: [
      '/images/gastronomico/wellbar/wellbar-gastronomico-1.jpg',
      '/images/gastronomico/wellbar/wellbar-gastronomico-2.jpg',
      '/images/gastronomico/wellbar/wellbar-gastronomico-3.jpg',
      '/images/gastronomico/wellbar/wellbar-gastronomico-4.jpg',
      '/images/gastronomico/wellbar/wellbar-gastronomico-5.jpg',
      '/images/gastronomico/wellbar/wellbar-gastronomico-6.jpg',
      '/images/gastronomico/wellbar/wellbar-gastronomico-7.jpg',
      '/images/gastronomico/wellbar/wellbar-gastronomico-8.jpg',
    ],
    description: [
      'Wellbar',
      nbsp,
      'Malabia 1682, CABA',
      'PROY: Arq. Lucas Ripani',
      'Arq. Felicitas Navia',
      'COLAB: Irina Teitelbaum'
    ]
  },
  15: {
    title: 'BoConcept',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/15-boconcept-comerciales.jpg',
    year: 2017,
    galleryImages: [
      '/images/comerciales/boconcept/boconcept-comerciales-1.jpg',
      '/images/comerciales/boconcept/boconcept-comerciales-2.jpg',
      '/images/comerciales/boconcept/boconcept-comerciales-4.jpg',
      '/images/comerciales/boconcept/boconcept-comerciales-5.jpg',
      '/images/comerciales/boconcept/boconcept-comerciales-7.jpg',
      '/images/comerciales/boconcept/boconcept-comerciales-8.jpg',
      '/images/comerciales/boconcept/boconcept-comerciales-9.jpg',
      '/images/comerciales/boconcept/boconcept-comerciales-10.jpg',
      '/images/comerciales/boconcept/boconcept-comerciales-11.jpg',
    ],
    description: [
      'BoConcept',
      nbsp,
      'Juncal 1116, CABA',
      'PROY: Arq. José Ripani',
      'DO: Arq. Gonzalo Obes, Arq. Mateo Ducau'
    ]
  },
  16: {
    title: 'Honda',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/16-honda-1960-comerciales.jpg',
    year: 2018,
    galleryImages: [
      '/images/comerciales/honda-1960/honda-1960-comerciales3.jpg',
      '/images/comerciales/honda-1960/honda-1960-comerciales4.jpg',
      '/images/comerciales/honda-1960/honda-1960-comerciales6.jpg',
    ],
    description: [
      'Honda',
      nbsp,
      'Av. Alicia Moreau de Justo 1960, CABA'
    ]
  },
  17: {
    title: 'Santa Cebada',
    category: CATEGORY_BARES,
    introImage: '/images/home/17-santa-cebada-gastronomico.jpg',
    year: 2019,
    galleryImages: [
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-1.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-2.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-3.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-4.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-5.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-6.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-7.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-8.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-9.jpg',
      '/images/gastronomico/santa-cebada/santa-cebada-gastronomico-10.jpg',
    ],
    description: [
      'Santa Cebada',
      nbsp,
      'Av. Córdoba 2001, CABA',
      'PROY: Arq. Lucas Ripani',
      'Arq. Agustina Perello',
      'DO: Arq. Ayelen Olivieri',
      'COLAB: Delfina Facio, Juan Segundo Dopazo'
    ]
  },
  18: {
    title: 'Nissan',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/18-nissan-comerciales.jpg',
    year: 2018,
    galleryImages: [
      '/images/comerciales/nissan/nissan.jpg',
    ],
    description: [
      'Nissan',
      nbsp,
      'Av. Presidente Perón 9106, CABA',
      'PROY: Arq. José Ripani',
      'DO: Arq. Gonzalo Obes',
    ]
  },
  19: {
    title: '7030 Beer House',
    category: CATEGORY_BARES,
    introImage: '/images/home/19-7030-gastronomico.jpg',
    year: 2018,
    galleryImages: [
      '/images/gastronomico/7030-bar/7030-gastronomico-1.jpg',
      '/images/gastronomico/7030-bar/7030-gastronomico-2.jpg',
      '/images/gastronomico/7030-bar/7030-gastronomico-3.jpg',
      '/images/gastronomico/7030-bar/7030-gastronomico-4.jpg',
      '/images/gastronomico/7030-bar/7030-gastronomico-5.jpg',
      '/images/gastronomico/7030-bar/7030-gastronomico-6.jpg',
      '/images/gastronomico/7030-bar/7030-gastronomico-7.jpg',
      '/images/gastronomico/7030-bar/7030-gastronomico-8.jpg',
    ],
    description: [
      '7030 Beer House',
      nbsp,
      'Gurruchaga 1493, CABA',
      'PROY: Arq. Lucas Ripani',
      'Arq. Brian Wault',
      'DO: Arq. Lucas Ripani'
    ]
  },
  20: {
    title: 'Beca Casa FOA',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/20-beca-casa-foa-comerciales.jpg',
    year: undefined,
    galleryImages: [
      '/images/comerciales/beca-casa-foa/beca-casa-foa-comerciales-1.jpg',
      '/images/comerciales/beca-casa-foa/beca-casa-foa-comerciales-2.jpg',
      '/images/comerciales/beca-casa-foa/beca-casa-foa-comerciales-3.jpg',
      '/images/comerciales/beca-casa-foa/beca-casa-foa-comerciales-4.jpg',
      '/images/comerciales/beca-casa-foa/beca-casa-foa-comerciales-05.jpg',
      '/images/comerciales/beca-casa-foa/beca-casa-foa-comerciales-5.jpg',
      '/images/comerciales/beca-casa-foa/beca-casa-foa-comerciales-06.jpg',
      '/images/comerciales/beca-casa-foa/beca-casa-foa-comerciales-08.jpg',
    ],
    description: [
      'Beca Casa FOA'
    ]
  },
  21: {
    title: 'Juan Pedro Caballero',
    category: CATEGORY_BARES,
    introImage: '/images/home/21-churreria-gastronomico.jpg',
    year: 2018,
    galleryImages: [
      '/images/gastronomico/churreria/churreria-gastronomico-1.jpg',
      '/images/gastronomico/churreria/churreria-gastronomico-2.jpg',
      '/images/gastronomico/churreria/churreria-gastronomico-3.jpg',
      '/images/gastronomico/churreria/churreria-gastronomico-4.jpg',
      '/images/gastronomico/churreria/churreria-gastronomico-5.jpg',
      '/images/gastronomico/churreria/churreria-gastronomico-6.jpg',
      '/images/gastronomico/churreria/churreria-gastronomico-7.jpg',
      '/images/gastronomico/churreria/churreria-gastronomico-8.jpg',
      '/images/gastronomico/churreria/churreria-gastronomico-9.jpg',
    ],
    description: [
      'Juan Pedro Caballero',
      nbsp,
      'Thames 1719, CABA',
      nbsp,
      'PROY y DO: Arq. Lucas Ripini',
      'Arq. Felicitas Navia',
      'COLAB: Roxana Paik, Pedro Mandalari'
    ]
  },
  22: {
    title: 'Citroen',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/22-citroen-2034-comerciales.jpg',
    year: 2016,
    galleryImages: [
      '/images/comerciales/citroen-2034/citroen-2034-comerciales-1.jpg',
      '/images/comerciales/citroen-2034/citroen-2034-comerciales-2.jpg',
      '/images/comerciales/citroen-2034/citroen-2034-comerciales-3.jpg',
      '/images/comerciales/citroen-2034/citroen-2034-comerciales-4.jpg',
    ],
    description: [
      'Citroen',
      nbsp,
      'Av. Alicia Moreau de Justo 2034, CABA'
    ]
  },
  23: {
    title: 'Braus - Tienda de cerveza',
    category: CATEGORY_BARES,
    introImage: '/images/home/23-braus-gastronomico.jpg',
    year: 2018,
    galleryImages: [
      '/images/gastronomico/braus/braus-gastronomico-1.jpg',
      '/images/gastronomico/braus/braus-gastronomico-2.jpg',
      '/images/gastronomico/braus/braus-gastronomico-3.jpg',
      '/images/gastronomico/braus/braus-gastronomico-4.jpg',
      '/images/gastronomico/braus/braus-gastronomico-7.jpg',
      '/images/gastronomico/braus/braus-gastronomico-8.jpg',
      '/images/gastronomico/braus/braus-gastronomico-9.jpg',
    ],
    description: [
      'Braus - Tienda de cerveza',
      nbsp,
      'Juncal 3626, CABA',
      nbsp,
      'PROY y DO: Arq. Lucas Ripani',
      'Arq. Felicitas Navia',
      'COLAB: Pedro Mandalari'
    ]
  },
  24: {
    title: 'Mini',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/24-mini-5901-comerciales.jpg',
    year: 2017,
    galleryImages: [
      '/images/comerciales/mini-5901/mini-5901-comerciales-1.jpg',
      '/images/comerciales/mini-5901/mini-5901-comerciales-2.jpg',
      '/images/comerciales/mini-5901/mini-5901-comerciales-3.jpg',
      '/images/comerciales/mini-5901/mini-5901-comerciales-4.jpg',
      '/images/comerciales/mini-5901/mini-5901-comerciales-5.jpg',
      '/images/comerciales/mini-5901/mini-5901-comerciales-6.jpg',
    ],
    description: [
      'Mini',
      nbsp,
      'Av. Libertador 5901, CABA'
    ]
  },
  25: {
    title: 'KTM',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/25-ktm-comerciales.jpg',
    year: 2019,
    galleryImages: [
      '/images/comerciales/ktm/ktm-comerciales-1.jpg',
      '/images/comerciales/ktm/ktm-comerciales-2.jpg',
      '/images/comerciales/ktm/ktm-comerciales-3.jpg',
      '/images/comerciales/ktm/ktm-comerciales-4.jpg',
      '/images/comerciales/ktm/ktm-comerciales-5.jpg',
      '/images/comerciales/ktm/ktm-comerciales-6.jpg',
    ],
    description: [
      'KTM',
      nbsp,
      'Panamericana Ramal Pilar KM 44, Bs As',
      'PROY: Arq. José Ripani',
      'DO: Arq. Fernanda Cazasnave'
    ]
  },
  26: {
    title: 'Bauleras',
    category: CATEGORY_CONCESIONARIAS,
    introImage: '/images/home/26-bauleras-comerciales.jpg',
    year: 2018,
    galleryImages: [
      '/images/comerciales/bauleras/bauleras-comerciales-1.jpg',
      '/images/comerciales/bauleras/bauleras-comerciales-2.jpg',
      '/images/comerciales/bauleras/bauleras-comerciales-3.jpg',
      '/images/comerciales/bauleras/bauleras-comerciales-4.jpg',
      '/images/comerciales/bauleras/bauleras-comerciales-5.jpg',
    ],
    description: [
      'Bauleras',
      nbsp,
      'Av. Scallabrini Ortiz 764',
      'PROY: Arq. José Ripani',
      'DO: Arq. Gonzalo Obes'
    ]
  },
  27: {
    title: 'Florería Atlántico Baños',
    category: CATEGORY_BARES,
    introImage: '/images/home/27-floreria-gastronomico.jpg',
    year: 2019,
    galleryImages: [
      '/images/gastronomico/floreria-atlantico/floreria-gastronomico-1.jpg',
      '/images/gastronomico/floreria-atlantico/floreria-gastronomico-2.jpg',
      '/images/gastronomico/floreria-atlantico/floreria-gastronomico-3.jpg',
      '/images/gastronomico/floreria-atlantico/floreria-gastronomico-4.jpg',
      '/images/gastronomico/floreria-atlantico/floreria-gastronomico-5.jpg',
      '/images/gastronomico/floreria-atlantico/floreria-gastronomico-6.jpg',
    ],
    description: [
      'Florería Atlántico Baños',
      nbsp,
      'Arroyo 872, CABA',
      'PROY: Arq. Lucas Ripani',
      'Arq. Felicitas Navia',
      'Emilia Carranza',
      'DO: Lucas Ripani'
    ]
  }
}