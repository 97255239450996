import React from 'react';

export default () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="60 100 500 450">
    <g id="logo_main">
      <path className="st3" d="M320.22,502.72l15.43-9.45l-102-164.75l1.08-0.25c22.8-5.25,41.96-17.08,56.95-35.15 c16.01-19.3,24.13-42.16,24.13-67.93c0-33.54-11.38-60.48-33.81-80.07c-22.37-19.51-52.83-29.41-90.55-29.41H70.66v377.04h17.28 V332.87h126.93L320.22,502.72z M87.94,315.59V132.98h102.47c33.46,0,60.15,8.32,79.33,24.73c19.1,16.36,28.78,39.07,28.78,67.48 c0,25.92-8.76,47.7-26.05,64.73c-12.73,12.54-28.67,20.57-47.38,23.89l-0.53,0.09l-33.37-53.82l-14.68,9.1l28.78,46.42H87.94z"
      />
    </g>
    <g id="logo_dot">
      <g id="XMLID_20_">
        <rect x="504.8" y="481.58" className="st3" width="21.14" height="21.14"
          id="XMLID_21_" />
      </g>
    </g>
    <g id="logo_arqs">
      <path d="M405.8,162.04c-0.25-1.47-0.41-3.68-0.41-6.54c-1.64,3.68-7.52,7.36-14.47,7.36c-7.11,0-12.92-5.31-12.92-12.1 c0-4.09,1.39-7.11,4.09-8.99c2.78-1.88,6.13-3.02,9.97-3.27l12.92-0.98v-1.64c0-6.95-3.92-11.04-10.22-11.04h-0.41 c-5.48,0-9.65,2.86-10.22,7.77h-5.31c0.49-7.19,7.03-12.26,15.7-12.26c10.3,0,15.78,7.03,15.78,15.53v17.17 c0,4.09,0.16,7.11,0.41,8.99H405.8z M404.98,141.85l-11.85,0.82c-5.89,0.41-9.81,3.27-9.81,7.77s3.52,7.93,8.18,7.93h0.82 c5.56,0.16,12.84-5.31,12.67-11.04V141.85z"
      />
      <path d="M422.96,121.16h4.91v9.32c1.88-6.3,6.79-9.73,12.67-9.73h3.68v4.91h-3.27c-7.77,0-12.67,6.3-12.67,16.52v19.87h-5.31 V121.16z"
      />
      <path d="M477.98,155.5c-2.45,4.42-8.18,7.36-13.65,7.36c-10.63,0-18.23-8.34-18.23-21.26c0-12.92,7.6-21.26,18.23-21.26 c5.48,0,11.2,2.94,13.65,7.36v-6.54h5.31v57.23h-5.31V155.5z M464.9,158.36c8.58,0,13.08-7.69,13.08-16.76 c0-9.08-4.5-16.76-13.08-16.76h-0.41c-8.42,0-13,7.69-13,16.76c0,9.07,4.58,16.76,13,16.76H464.9z"
      />
      <path d="M498.5,149.78c0.57,5.48,4.74,8.58,10.55,8.58h0.82c5.64,0,9.24-2.86,9.24-7.36c0-3.27-1.96-5.23-7.19-6.3l-5.97-1.23 c-6.95-1.55-11.12-4.66-11.12-11.12c0-7.52,6.7-12.02,14.63-12.02c10.14,0,14.88,5.97,15.37,13.08h-5.31 c-0.49-4.58-4.25-8.58-9.65-8.58h-0.82c-5.56,0-8.91,3.27-8.91,7.44c0,3.02,2.29,5.07,7.44,6.13l6.38,1.39 c6.54,1.39,10.46,4.33,10.46,10.79c0,7.69-6.05,12.26-14.96,12.26c-10.38,0-15.94-5.4-16.27-13.08H498.5z"
      />
    </g>
  </svg>
);