import React from 'react';
import isUndefined from 'lodash/isUndefined';

import {
  CATEGORY_CONCESIONARIAS,
  CATEGORY_VIVIENDAS,
  CATEGORY_BARES,
  CATEGORY_ESTUDIO
 } from '../../store/constants';
 import hamburgerIcon from '../../assets/hamburgerIcon.svg'
 import closeIcon from '../../assets/close.svg'
 

 
const NavItem = ({
  title,
  selectedCategory,
  navitemActive,
  category,
  setCategory,
  setGalleryById,
  setGalleryList,
  closeMobileMenu,
  scrollToTop
}) => {  
  const shouldRender = category && selectedCategory === category ;
  const getActiveState = () => {
    if (isUndefined(selectedCategory)) {
      return null;
    }

    if (navitemActive && selectedCategory === category && selectedCategory !== CATEGORY_ESTUDIO) {
      return 'active';
    }

    return 'not-active';
  }

  const handleClick = () => {
    closeMobileMenu();
    scrollToTop()
    if (category === CATEGORY_ESTUDIO) {
      setCategory(undefined);
      setGalleryById(CATEGORY_ESTUDIO)
      return;
    }
    setCategory(category);
  }
  const handleClose = () => {
    setCategory(undefined);
    setGalleryById(undefined);
  };
  const handleSeeCategoryList = () => {
    setGalleryList(category);
  }
  
  return (
    <li className={getActiveState()} >
      {shouldRender && 
        <button onClick={handleClose} className="close-button" >
          x
        </button>
      }
      <button onClick={handleClick} className="category-button close-category" >
        {title}
      </button>
      {shouldRender && category !== CATEGORY_ESTUDIO &&
        <button onClick={handleSeeCategoryList} className="list-button hidden-mobile" >
          ver lista
        </button>
      }
    </li>
  );
}

export default function Navigation(props) {
  const closeMenuHandler = () => {
    props.setCategory(undefined);
    props.setGalleryById(undefined);
    props.closeMobileMenu();
  }
  
  const openMenuHandler = () => {
    // console.log('OPEN_MOBILE_MENU')
    props.openMobileMenu();
  }
  
  return (
    <nav className={props.isMobileMenuOpen ? 'isOpen' : null}>
      {
        !props.isSilderOpen && !props.isMobileMenuOpen  ? 
        <button className="mobile-nav-button"><img src={hamburgerIcon} alt="Menú" onClick={openMenuHandler} /></button> :
        <button className="mobile-nav-button"><img src={closeIcon} alt="Cerrar menú" onClick={closeMenuHandler} /></button>

      }
      
      
      <ul className={props.isMobileMenuOpen ? 'isOpen' : null} >
        <NavItem {...props} category={CATEGORY_CONCESIONARIAS} title="comerciales" />
        <NavItem {...props} category={CATEGORY_VIVIENDAS} title="viviendas" />
        <NavItem {...props} category={CATEGORY_BARES} title="gastronomico" />
        <NavItem {...props} category={CATEGORY_ESTUDIO} title="el estudio" />
      </ul>

      {
        props.isSilderOpen ?
        <>
          <button className="close-button" onClick={props.closeSlider} ><img src="/images/arrow-right.svg" alt="Close slider" /></button>
          <button className="view-category-list" onClick={() => console.log('ver lista')} >ver lista</button>
        </>: 
          null
          
      }
    </nav>
  );
}