import get from 'lodash/fp/get';
import { CATEGORY_ESTUDIO } from '../constants'

export const getIsSliderOpen = state => !!get('slider.isOpen')(state);

export const getSliderId = get('slider.id')

export const isEstudioOpen = state => getIsSliderOpen(state) && get('slider.id')(state) === CATEGORY_ESTUDIO

export const getSelectedSliderDescription = get('ui.descriptionItems')

export const isCategoryListOpen = state => !!get('slider.categoryListOpen')(state)

export const getCategoryListOpen = get('slider.categoryListOpen')