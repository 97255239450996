import React from 'react';
import './Estudio.scss';

function Estudio({ isActive, width }) {
  const estudioStyles = {
    width
  }
  
  return (
    <div className={`Estudio ${isActive ? '' : 'opacity-0'}`} style={estudioStyles} >
      <p>JOSÉ RIPANI </p>
      <p>Arquitecto UBA, egresado 1987.</p>
      <br />
      <p>LUCAS RIPANI</p>
      <p>Arquitecto uba, egresado 2017.</p>
      <p>Docente de Arquitectura 4 - Cátedra LCJ (Lestard Cajide Janchez)</p>
      <br />
      {/* <p>
        Estudio multidisciplinario de arquitectura fundado en (año) en Buenos Aires, Argentina, por José Ripani, actualmente fusionado con Lucas Ripani, ambos arquitectos graduados de la Universidad de Buenos Aires, donde actualmente Lucas es docente de la cátedra de diseño Lestard-Cajide-Janches.
      </p>
      <p>
        La superposición entre la sensibilidad y lo pragmático conducen ideas basadas en la innovación y creatividad, trabajando siempre en conjunto y enfocándose de manera individual en cada desafío.
      </p>
      <p>
        El foco en los detalles, el dibujo, y los materiales nobles, dan lugar a proyectos con una fuerte impronta estética, generando una relación transparente con nuestros clientes que se mantiene a través de los años. 
      </p> */}
      <br />
      <p>
        <a href="mailto:info@estudioripani.com">info@estudioripani.com</a>
        
      </p>
      <p>
        +5411 4777 1211
      </p>
      <p>
        Ortega y Gasset 1710 - CABA, Buenos Aires
      </p>
    </div>
  )
}

export default Estudio;



