import React, { useRef, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import pipe from 'lodash/fp/pipe';
import debounce from 'lodash/debounce';

import Gallery from './Gallery';
import Navigation from './Navigation';
import Slider from './Slider';
import Logo from '../assets/Logo';

import { closeSlider } from '../store/actions/slider'

import { getSelectedSliderDescription, getIsSliderOpen, isCategoryListOpen } from '../store/selectors/slider';
import { setCategory } from '../store/actions/category';
import { setGalleryById, setGalleryList } from '../store/actions/gallery';
import { setScrollDirectionFromPosition } from '../store/actions/scroll';
import { closeMobileMenu, scrollToTop } from '../store/actions/ui';
import { getState, dispatch } from '../store/StoreProvider'
import { isEstudioOpen } from '../store/selectors/slider'

import SwipeGalleryManager from './SwipeGalleryManager';

import './App.scss'

window.getState = getState

window.addEventListener('keydown', event =>{
  if (event.key === 'Escape'){
    dispatch(closeSlider())
  }
})


window.addEventListener('gallery_close', () => {
  const { scroll } = getState();
  const newPosition = scroll.scrollPositionBeforeOpenGallery;
  window.scrollTo(0, newPosition);
});

window.addEventListener('scroll_to_top', () => {
  window.scrollTo(0, 0);
})

let last_known_scroll_position = 0;
let ticking = false;

let scrollHandler = e => {
  last_known_scroll_position = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(() => {
      dispatch(setScrollDirectionFromPosition(last_known_scroll_position))
      ticking = false;
    });

    ticking = true;
  }
}

const debouncedScroll = debounce(scrollHandler, 10);

window.addEventListener('mousewheel', debouncedScroll);

const descriptionMapStateToProps = state => ({
  isSilderOpen: getIsSliderOpen(state),
  isEstudioOpen: isEstudioOpen(state),
  isCategoryListOpen: isCategoryListOpen(state) 
})

const descriptionMapDispatchToProps = {
  setGalleryList
}
const Description = connect(
  descriptionMapStateToProps,
  descriptionMapDispatchToProps
)(
  ({isSilderOpen, isEstudioOpen, description, setGalleryList, isCategoryListOpen}) => {
    return (
      <div className={
          `description ${isSilderOpen ? 'opacity-1': 'opacity-0'} ` +
          `${isEstudioOpen ? 'isEstudioOpen' : ''} ` +
          `${isCategoryListOpen ? 'isCategoryListOpen' : ''}`
        } >
        <ul>
          {
            description.map((li, index) => {
              return <li key={index}>{li}</li>
            })
          }
        </ul>
        <button className="view-category-list" onClick={() => setGalleryList()} >ver lista</button>
      </div>
    )
  }
)

const logoMapDispatchToProps = {
  setCategory,
  setGalleryById,
  closeMobileMenu,
  scrollToTop
}
const LogoWrapper = connect(null, logoMapDispatchToProps)(({ setCategory, setGalleryById, closeMobileMenu, scrollToTop}) => {
  const clickHandler = () => {
    closeMobileMenu();
    setCategory(undefined);
    setGalleryById(undefined);
    setTimeout(() => {
      scrollToTop()
    }, 0)
  }
  return (
    <div className="logo-wrapper">
      <button onClick={clickHandler} >
        <Logo />
      </button>
    </div>
  )
});

const InstagramAnchor = () => (
  <div className="instagram-wrapper">
    <a href="https://instagram.com/estudio.ripani" rel="noopener noreferrer" target="_blank" >
      @estudio.ripani
    </a>
  </div>
);

function App({ description, isSilderOpen, closeSlider}) {
  const mainEl = useRef(null);
  const [columnWidth, setColumnWidth] = useState(0);
  const [mainWidth, setMainWidth] = useState(0);
  const [gapWidth, setGapWidth] = useState(0);

  const getColumnWidthFromRef = ref => ref.current.clientWidth / 3;
  const setColumnWidthFromRef = pipe(getColumnWidthFromRef, setColumnWidth);
  const resizeHandler = useCallback(() => {
    setColumnWidth(mainEl);
    setMainWidth(mainEl.current.clientWidth);
    setGapWidth(mainEl.current.offsetLeft);
  }, []);
  
  useEffect(() => {
    setColumnWidthFromRef(mainEl);
    setMainWidth(mainEl.current.clientWidth);
    setGapWidth(mainEl.current.offsetLeft);
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, [resizeHandler, columnWidth, setColumnWidthFromRef, gapWidth]);

  return (
    <div className="App" >
      <aside style={{ width: columnWidth}}>
        <LogoWrapper />
        <Navigation />
        <Description description={description || []} />
        <InstagramAnchor />
      </aside>
      <main ref={mainEl} >
        <Gallery />
        <Slider columnWidth={columnWidth} width={mainWidth} >
          <SwipeGalleryManager mainWidth={mainWidth} gapWidth={gapWidth} />
        </Slider>
      </main>
    </div>
  );
}

const mapStateToProps = state => ({
  description: getSelectedSliderDescription(state),
  isSilderOpen: getIsSliderOpen(state),
  isEstudioOpen: isEstudioOpen(state)
});

const mapDispatchToProps = {
  closeSlider
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
