import { connect } from 'react-redux';
import SwipeGalleryManager from './SwipeGalleryManager';

import { getSelectedCategoryItems, isLastCategorySliderOpen, getActiveCategory } from '../../store/selectors/category';
import { getActiveCategoryList } from '../../store/selectors/categoriesLists';
import { isEstudioOpen, isCategoryListOpen, getCategoryListOpen } from '../../store/selectors/slider';
import { setDescriptionItemsByID } from '../../store/actions/ui'

const mapStateToProps = state => ({
  isEstudioOpen: isEstudioOpen(state),
  categoryItems: getSelectedCategoryItems(state),
  isLastCategorySliderOpen: isLastCategorySliderOpen(state),
  categoryListText: getActiveCategoryList(state),
  currentCategory: getActiveCategory(state),
  isOnlyCategoryListOpen: isCategoryListOpen(state),
  openCategoryList: getCategoryListOpen(state)
});

const mapDispatchToProps = ({
  setDescriptionItemsByID
})

export default connect(mapStateToProps, mapDispatchToProps)(SwipeGalleryManager);
