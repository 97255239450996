import get from 'lodash/fp/get';
import omitBy from 'lodash/omitBy';
import slice from 'lodash/slice';
import keys from 'lodash/keys';
import concat from 'lodash/concat';
import indexOf from 'lodash/indexOf';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import last from 'lodash/last';
import pipe from 'lodash/fp/pipe';

import { getGalleryItems } from './gallery';

export const getSelectedCategory = get('category.selected');

export const getNavitemActive = get('category.navitemActive');

export const getCategoryById = (state, id) => {
  const galleryItems = get('galleryItems')(state);
  const category = get(`[${id}].category`)(galleryItems);

  return category;
}

const getCategoryItemsWithIds = state => {
  const galleryItems = getGalleryItems(state);

  const galleryItemsIds = keys(galleryItems)
  return reduce(galleryItemsIds, (items, key) => {
    return [
      ...items,
      { ...galleryItems[key], item_id: key }
    ]
  }, [])
}

export const getActiveCategory = state => pipe(
  get('slider.id'),
  id => getCategoryById(state, id)
)(state)

export const getSelectedCategoryItems = state => {
  const sliderItemId = get('slider.id')(state);
  const currentSliderItemCategory = getCategoryById(state, sliderItemId)
  const galleryItemsWithIds = getCategoryItemsWithIds(state)

  const categoryItems = omitBy(galleryItemsWithIds, item => 
    item.category !== currentSliderItemCategory
  );
  const itemsWithIdsAsKeys = reduce(keys(categoryItems), (acc, currKey) => {
    return {
      ...acc,
      [categoryItems[currKey].item_id]: categoryItems[currKey]
    }
  }, {})

  const indexes = keys(itemsWithIdsAsKeys);

  const indexOfSelectedId = indexOf(indexes, sliderItemId);
  const orderedCategoryItemsIndexes = concat(
    slice(indexes, indexOfSelectedId),
    slice(indexes, 0, indexOfSelectedId)
  );

  const orderedCategoryItems = map(orderedCategoryItemsIndexes, id => {
    return itemsWithIdsAsKeys[id]
  });

  return orderedCategoryItems;
}

export const isLastCategorySliderOpen = state => {
  const categoryItems = getCategoryItemsWithIds(state);
  const currentItemId = get('slider.id')(state)

  const currentCaregory = getCategoryById(state, currentItemId)

  const itemsFromSelectedCategory = filter(categoryItems, ['category', currentCaregory])
  const lastItemId = get('item_id')(last(itemsFromSelectedCategory))

  return lastItemId === currentItemId;
}