import {
  CATEGORY_CONCESIONARIAS,
  CATEGORY_VIVIENDAS,
  CATEGORY_BARES
} from '../constants'

const categoriesListsInitialState = {
  [CATEGORY_VIVIENDAS]: {
    2018: [{
      title: 'FINCAS DE HUDSON',
      location: 'Barrio Cerrado, Guillermo Hudson, Bs As'
    }],
    2012: [{
      title: 'CASA PERNANCIC',
      location: 'Estancias del pilar , Barrio Cerrado, Pilar, Bs As',
      sliderId: 1
    }],
    2008: [
      {
        title: 'ESTANCIAS DEL PILAR',
        location: 'Barrio Cerrado, Pilar, Bs As',
        sliderId: 9
      },
      {
        title: 'CASA RUDY'
      }
    ],
    2005: [{
      title: 'CASA ABRIL',
    }],
    2004: [
      {
      title: 'LOZANO CASA CUBA',
      },
      {
        title: 'CASA QUILMES MARA'
      }
    ]
  },


  [CATEGORY_CONCESIONARIAS]: {
    2019: [
      {
        title: 'BMW / MINI',
        location: 'Av. Pres. Figueroa Alcorta 7730, CABA',
        sliderId: 2
      },
      {
        title: 'CHRYSLER/JEEP',
        location: 'Carlos Pellegrini 1526, CABA'
      },
      {
        title: 'KTM',
        location: 'Panamericana Ramal Pilar KM 44, Bs As',
        sliderId: 25
      }
    ],

    2018: [
      {
        title: 'DS',
        location: 'Av. Libertador 14400, Martínez, Bs As',
        sliderId: 13
      },
      {
        title: 'HONDA 1960',
        location: 'Av. Alicia Moreau de Justo 1960, CABA',
        sliderId: 16
      },
      {
        title: 'NISSAN 9106',
        location: 'Av. Presidente Perón 9106, CABA',
        sliderId: 18
      },
      {
        title: 'NISSAN TALLER',
        location: 'Brandsen 3752'
      },
      {
        title: 'ESPASA VW',
        location: 'Av. Triunvirato 3661, CABA'
      },
    ],

    2017: [
      {
        title: 'MINI 5901',
        location: 'Av. Libertador 5901, CABA',
        sliderId: 24
      },
      {
        title: 'BOCONCEPT',
        location: 'Juncal 1116, CABA',
        sliderId: 15
      },
    ],

    2016: [
      {
        title: 'AUDI 495',
        location: 'Av. Leandro Alem 495, CABA'
      },
      {
        title: 'CITROËN',
        location: 'Av. Alicia Moreau de Justo 2034, CABA'
      },
      {
        title: 'TALLER FORD',
        location: 'Santiago del Estero 1368'
      },
    ],

    2015: [
      {
        title: 'TALLER AUDI',
        location: 'Av. San Juan 1740, CABA',
        sliderId: 7
      },
      {
        title: 'BMW 5900',
        location: 'Av. Libertador 5900, CABA'
      },
      {
        title: 'MINI 3048',
        location: 'Av. Libertador 3048, CABA'
      },
      {
        title: 'FORD 116',
        location: 'Cerrito 116, CABA'
      },
    ],

    2013: [
      {
        title: 'AUDI 201',
      },
      {
        title: 'BMW Taller Juramento',
        location: 'Juramento, CABA'
      },
      {
        title: 'MINI 4996',
        location: 'Av. Libertador 4996, Martínez, Bs As'
      },
      {
        title: 'FORD 901',
        location: 'Alvarez Thomas 901, CABA'
      },
    ],

    2012: [
      {
        title: 'FORD 702',
        location: 'Av. Montes de Oca 702, CABA'
      },
      {
        title: 'FORD CASTELAR',
        location: 'Av. Zeballos 3133'
      },
      {
        title: 'HONDA 3116',
        location: 'Av. Libertador 3116, CABA'
      },
      {
        title: 'ESPASA TALLER VW',
        location: 'Av. Los Incas 4378, CABA',
        sliderId: 4
      },
    ],

    2011: [
      {
        title: 'HONDA 2500',
        location: 'San Martín 6500'
      },
    ],
    2010: [
      {
        title: 'BMW 6500',
        location: 'San Martin 6500',
        sliderId: 6
      },
    ],
    2008: [
      {
        title: 'MINI 1800',
        location: 'Av. Libertador 1800'
      },
    ],
    2007: [
      {
        title: 'Tours CITROËN',
        location: 'Panamericana Ramal Pilar KM 46800, Bs As',
        sliderId: 11
      },
    ],
    logos: '/images/logos/logos_comerciales.png'
  },


  [CATEGORY_BARES]: {
    2019: [
      {
        title: 'DUCA Café',
        location: 'Thames 1759, CABA',
        sliderId: 3
      },
      {
        title: 'SANTA CEBADA',
        location: 'Av. Córdoba 2001, CABA',
        sliderId: 17
      },
      {
        title: 'FUEGO Y SABOR',
        location: 'Thames y Gorriti, CABA'
      },
      {
        title: 'FLORERÍA ATLÁNTICO BAÑOS',
        location: 'Arroyo 872, CABA',
        sliderId: 27
      },
      {
        title: 'PIRAMIDE',
        location: 'Virrey Loreto 2103, CABA'
      },
      {
        title: 'MAUER BAR'
      },
      {
        title: 'COWI'
      },
      {
        title: '7030 NÚÑEZ'
      }
    ],

    2018: [
      {
        title: 'JUAN PEDRO CABALLERO',
        location: 'Thames 1719, CABA'
      },
      {
        title: 'DONUT THERAPY',
        location: 'Thames 1993, CABA'
      },
      {
        title: '7030 BEER HOUSE',
        location: 'Gurruchaga 1493, CABA',
        sliderId: 19
      },
      {
        title: 'BRAUS - TIENDA DE CERVEZA',
        location: 'Juncal 3626, CABA',
        sliderId: 23
      },
      {
        title: 'PUNTO VEGETAL',
        location: 'José A. Cabrera 4864, CABA'
      },
      {
        title: 'TOMATE - ESTACIÓN DE SABORES',
        location: 'El Salvador 2646, CABA',
        sliderId: 12
      },
      {
        title: 'WELLBAR',
        location: 'Malabia 1682, CABA',
        sliderId: 14
      },
      {
        title: 'GINTONERÍA ROSARIO',
        location: 'Balcarce 340, Rosario, Santa Fe',
        sliderId: 5
      },
    ],

    2017: [
      {
        title: 'BOTICARIO BAR',
        location: 'Honduras 5207, CABA',
        sliderId: 10

      },
      {
        title: 'PARQUE BAR',
        location: 'Thames 1472, CABA',
        sliderId: 8

      },
      {
        title: 'LOGIA AHUMADOS',
        location: 'Bonpland 1611, CABA'

      },
      {
        title: 'MATADEROS',
        location: 'Mercado Belgrano, CABA'

      },
    ]
  }
}


const categoriesLists = (state = categoriesListsInitialState) => state

export default categoriesLists