import React from 'react';
import Estudio from './Estudio';
import { connect } from 'react-redux'
import { setGalleryById } from '../../store/actions/gallery'
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined'
import { getActiveCategory } from '../../store/selectors/category'
import { setCategoryOrder, setCategory } from '../../store/actions/category'

import './SwipeGalleryManager.scss';

const SwipeItems = props => {
  const {setDescriptionItemsByID} = props
  if (get(props, 'items.length')){
    const [currentItem, ...restOfItems] =  props.items
    const mobileItems = restOfItems.map(item => ({
      ...item,
      isMobile: true
    }))
    const allItems = [currentItem, ...mobileItems]
    
    return allItems.map(item => {
      return item.galleryImages.map((image, index) => (
        <div
          key={index}
          className={`SwipeItems ${item.isMobile ? 'mobile-item' : 'first-item'}`}
          onTouchStart={() => setDescriptionItemsByID(item.item_id) }
         >
            <img src={image} alt={`${item.title} ${index + 1}`} />
        </div>
      ))
    })
  }

  return null;
}


const categoryListMapStateToProps = state => ({
  activeCategory: getActiveCategory(state)
})
const categoryListMapDispatchToProps = {
  setGalleryById,
  setCategory,
  setCategoryOrder
}

const CategoryList = connect(
  categoryListMapStateToProps, categoryListMapDispatchToProps
)(({ width, list, setGalleryById, activeCategory }) => {
  const categoryListStyles = {
    width
  }

  let listItems = [];

  const onClickHandler = (id) => () => {
    setGalleryById(false)
    setCategory(undefined)

    setTimeout(() => {
      setGalleryById(`${id}`)
      setCategoryOrder(activeCategory)
    }, 500)
  }

  
  for (const key in list) {
    if (key === 'logos') {
      listItems.push(
        <div key="logos" className="item-wrapper image-wrapper" >
          <img src={list[key]} alt="Logos de trabajos realizados" />
        </div>
      )
    }

    else {
      listItems.push(
        <div className="item-wrapper" key={key}>
          <h4 className="year" >{key}</h4>
          {list[key].map((item, index) => {
            return (
              <div className="description-wrapper" key={item.title + index}>
                {
                  item.title &&
                  item.sliderId ?
                  <button onClick={onClickHandler(item.sliderId, undefined)} >
                    {item.title}
                  </button> :
                  <p>{item.title}</p>
                }
                {/* {item.location && <p>{item.location}</p>} */}
              </div>
            )
          })}
        </div>
      )
    }

  }


  return (
    <div className="CategoryList" style={categoryListStyles} >
      {listItems}
    </div>
  );
});

export default class SwipeGalleryManager extends React.Component {
  render() {
    const {
      categoryItems,
      sliderContainerWidth,
      width,
      isLastCategorySliderOpen,
      isEstudioOpen,
      categoryListText,
      currentCategory,
      isOnlyCategoryListOpen,
      openCategoryList,
      gapWidth,
      mainWidth,
      setDescriptionItemsByID
    } = this.props;

    return (
      <div id="SwipeGalleryManager" className="SwipeGalleryManager"
        // style={{ minWidth: mainWidth + gapWidth }}
        >
        {
          !isOnlyCategoryListOpen && <SwipeItems items={categoryItems} setDescriptionItemsByID={setDescriptionItemsByID} />
        }
        {
           ((isLastCategorySliderOpen && false) || (openCategoryList)) ? (
            <CategoryList width={sliderContainerWidth} list={categoryListText} />
           ) : null
        }

        {
          !isOnlyCategoryListOpen && 
          isUndefined(currentCategory) &&
          <Estudio isActive={isEstudioOpen} width={width} />
        }
      </div>
    );
  }
}