import { OPEN_MOBILE_MENU, CLOSE_MOBILE_MENU } from '../constants'

export default function ui(state = {}, action = {}) {

    if (action.type === OPEN_MOBILE_MENU) {
        return {
            ...state,
            isMobileMenuOpen: true
        }
    }

    if (action.type === CLOSE_MOBILE_MENU) {
        return {
            ...state,
            isMobileMenuOpen: false
        }
    }

    if(action.type === 'SET_DESCRIPTION_ITEMS') {
        return {
            ...state,
            descriptionItems: action.descriptionItems
        }
    }
    
    return state;
}