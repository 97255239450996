import { SET_GALLERY_BY_ID, SHOW_CATEGORY_LIST } from '../constants';

export default function(state = {}, action = {}) {
  if (action.type === SET_GALLERY_BY_ID) {
    if(action.id) {
      return {
        ...state,
        isOpen: true,
        id: action.id
      };
    }
    
    return {
      ...state,
      isOpen: false,
      categoryListOpen: undefined
    };
  }

  if (action.type === SHOW_CATEGORY_LIST) {
    return {
      ...state,
      isOpen: true,
      categoryListOpen: action.category
    }
  }
  return state
} 