import { combineReducers } from 'redux';

import galleryItems from './galleryItems';
import slider from './slider';
import category from './category';
import scroll from './scroll';
import categoriesLists from './categoriesLists'
import ui from './ui'
 
export default combineReducers({
  galleryItems,
  slider,
  category,
  scroll,
  categoriesLists,
  ui
});