import get from 'lodash/get'
import { setGalleryScrollPreviousPos, setScrollDirectionFromPosition } from './scroll'
import { SET_GALLERY_BY_ID, SHOW_CATEGORY_LIST } from '../constants';
import { getSelectedCategoryItems } from '../selectors/category'
import { closeSlider } from '../actions/slider';
import { scrollToTop, setDescriptionItemsByID } from './ui'


export function setGalleryById(id) {
  return (dispatch, getState) => {
    const state = getState();
    
    if (id) {
      dispatch(setGalleryScrollPreviousPos());
      dispatch(setDescriptionItemsByID(id))
    }

    if (!id) {
      dispatch(scrollToTop());

      dispatch(setScrollDirectionFromPosition(state.scroll.scrollPositionBeforeOpenGallery))

      dispatch(setDescriptionItemsByID(null))
    }

    
    
    dispatch({
      type: SET_GALLERY_BY_ID,
      id
    });
  };
}

export function setGalleryList(category) {
  return (dispatch, getState) => {
    if (category) {
      return dispatch({
        type: SHOW_CATEGORY_LIST,
        category
      })
    }
    const state = getState();
    const itemId = get(state, 'slider.id')
    const activeCategory = get(state, `galleryItems.${itemId}.category`)
    
    return dispatch({
      type: SHOW_CATEGORY_LIST,
      category: activeCategory
    })
  }
}

export function nextGallery() {
  return (dispatch, getState) => {
    const currentItemIndex = 0;
    const state = getState();
    const categoryItems = getSelectedCategoryItems(state);
    const itemsLength = get(categoryItems, 'length');
    const nextItemIndex = currentItemIndex + 1 === itemsLength ? 0 : currentItemIndex + 1;
    const nextItemId = categoryItems[nextItemIndex].item_id;

    dispatch(closeSlider());
    setTimeout(() => {
      dispatch(setGalleryById(nextItemId));
    }, 500);
      
  }
}