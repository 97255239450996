import { SET_SCROLL_DIRECTION_FROM_POSITION, SET_USER_SCROLLING, SET_SCROLL_POSITION_BEFORE_OPENING_GALLERY } from '../constants'

const scrollInitialState = {
  direction: undefined,
  position: 0,
  isScrolling: false,
  scrollPositionBeforeOpenGallery: 0
}

export default function(state = scrollInitialState, action = {}) {
  if (action.type === SET_SCROLL_DIRECTION_FROM_POSITION) {
    return {
      scrollPositionBeforeOpenGallery: state.scrollPositionBeforeOpenGallery,
      isScrolling: true,
      direction: action.direction,
      position: action.position
    };
  }

  if (action.type === SET_USER_SCROLLING) {
    return {
      ...state,
      isScrolling: action.isScrolling
    }
  }

  if (action.type === SET_SCROLL_POSITION_BEFORE_OPENING_GALLERY) {
    return {
      ...state,
      scrollPositionBeforeOpenGallery: action.scrollPositionBeforeOpenGallery
    }
  }

  return state
}